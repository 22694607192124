exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-delete-account-js": () => import("./../../../src/pages/delete-account.js" /* webpackChunkName: "component---src-pages-delete-account-js" */),
  "component---src-pages-english-for-kids-js": () => import("./../../../src/pages/english-for-kids.js" /* webpackChunkName: "component---src-pages-english-for-kids-js" */),
  "component---src-pages-french-for-kids-js": () => import("./../../../src/pages/french-for-kids.js" /* webpackChunkName: "component---src-pages-french-for-kids-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-italian-for-kids-js": () => import("./../../../src/pages/italian-for-kids.js" /* webpackChunkName: "component---src-pages-italian-for-kids-js" */),
  "component---src-pages-languakids-apps-js": () => import("./../../../src/pages/languakids-apps.js" /* webpackChunkName: "component---src-pages-languakids-apps-js" */),
  "component---src-pages-oldindex-js": () => import("./../../../src/pages/oldindex.js" /* webpackChunkName: "component---src-pages-oldindex-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-spanish-for-kids-js": () => import("./../../../src/pages/spanish-for-kids.js" /* webpackChunkName: "component---src-pages-spanish-for-kids-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

